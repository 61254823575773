<template>
  <el-container>
    <el-row style="width: 100%">
      <el-col :span="24">
        <div class="cardTitle">选项列表</div>
        <el-button type="primary" @click="createOption()" class="cardTitleBtn"
          >新建选项</el-button
        >
      </el-col>
      <el-col :span="8" class="card_style" v-for="item in optionList" :key="item.guid">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ item.name }}</span>
            <el-button style="float: right; padding: 3px 0" type="primary" @click="getDetail(item.guid)">编辑</el-button>
          </div>
          <div v-for="(o,i) in item.optionList" :key="o.value" class="text item">
            <div v-if="i<=2">{{ o.value + ":"+ o.text }}</div>
            <div v-if="i==3 && item.optionList.length == 4">{{ o.value + ":"+ o.text }}</div>
            <div v-if="i==3 && item.optionList.length > 4">...</div>
          </div>
        </el-card>
      </el-col>
    </el-row>
  </el-container>
</template>
<style>
.text {
  font-size: 14px;
}

.card_style {
  margin-top: 20px;
  padding-left: 40px;
}

.item {
  margin-bottom: 18px;
}

.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}

.box-card {
  width: 100%;
}
</style>
<script>
export default {
  data() {
    return {
      userInfo: {},
      optionList: [],
      recordList: {},
    };
  },
  mounted() {
    let self = this;
    self.userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
    self.getOptionList();
  },
  methods: {
    getOptionList() {
      let self = this;
      self.$axios
        .get("/option-group/page", {
          headers: { token: self.userInfo.token },
        })
        .then(function (response) {
          self.optionList = response.data.data.records;
          self.recordList = response.data.data;
        })
        .catch(function (error) {
          self.$message({
            message: "选项列表获取失败：" + error.response.data.message,
            type: "warning",
          });
        });
    },
    getDetail(guid) {
      let self = this;
      self.$router.push({
        name: "option_group_detail",
        query: { guid: guid },
      });
    },
    createOption() {
      let self = this;
      self.$router.push({ name: "option_group_detail", query: { guid: null } });
    },
  },
};
</script>

<style scoped>
</style>